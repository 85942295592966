@import "palettes";
@import "theme";
@import "mixins";

h1,
h2,
h3,
h4 {
  .anchor {
    display: inline-block;
    position: relative;
    width: 0;
    margin: 0;
    left: 1em;
    color: $accent;
    opacity: 0;
    font-size: 0.75em;
    text-decoration: none;
    transition: opacity $fast, color $fast;
  }

  &:hover .anchor,
  .anchor:focus {
    opacity: 1;
  }
}

[data-glow="true"] {
  animation: highlight_glow 2s;
}

@keyframes highlight_glow {
  0% {
    background: none;
  }
  10% {
    background: $light-accent;
  }
  100% {
    background: none;
  }
}
